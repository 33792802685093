



















































































































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component
export default class Tramites extends Vue {
  private toLink(item: any) {
    if (item && item != "") {
      window.open(item, "_blank");
    } else {
      return;
    }
  }
}
