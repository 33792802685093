





























































































































import { Component, Vue, Watch } from "vue-property-decorator";
import moment from "moment";
import "moment/locale/es";

@Component({
  components: {}
})
export default class Efemerides extends Vue {
  private aEfemerides: Array<any> = [];
  private currentPage = 1;
  private total = 0;

  @Watch("currentPage")
  private currentPageWatch(newValue: any) {
    if (newValue >= 0 && newValue <= Math.ceil(this.total / 9)) {
      this.getEfemerides();
    }
  }

  private mounted() {
    this.getEfemerides();
  }

  private getEfemerides() {
    this.$axios
      .get("efemerides/general?pagina=" + this.currentPage)
      .then(res => {
        this.aEfemerides = res.data.efemerides;
        for (let i = 0; i < this.aEfemerides.length; i++) {
          this.aEfemerides[i].fecha_efemeride = moment(
            this.aEfemerides[i].fecha_efemeride
          ).format("LLLL");
          this.aEfemerides[i].newFecha = this.aEfemerides[
            i
          ].fecha_efemeride.split(" ", 6);
        }
        console.log(this.aEfemerides);
        this.total = res.data.total;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }
}
